/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { bool, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import { useLocation } from 'react-router-dom';
import css from './OrderBreakdown.module.css';

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
    currentListing,
    showPriceDetails,
    amount,
    restAmount,
    isAdvancedExperienceBooking,
    isEarlyExperienceBooking,
    isTransactionPage,
    isOrderIdInList,
  } = props;

  const strikeThroughTotal = props?.strikeThroughTotal ? props?.strikeThroughTotal : false;
  const location = useLocation();
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const lineItems = transaction.attributes.lineItems;
  const isCancelledState =
    transaction?.attributes?.lastTransition == 'transition/cancel-exp' ||
    transaction?.attributes?.lastTransition == 'transition/decline-customer-after-exp' ||
    transaction?.attributes?.lastTransition == 'transition/decline-provider-after-exp';
  const isPayableState =
    transaction?.attributes?.lastTransition == 'transition/payment-possible-exp';
  const isPayedState =
    transaction?.attributes?.lastTransition != 'transition/payment-expired-exp' &&
    transaction?.attributes?.transitions?.length > 3;
  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible - deleted because not used
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */
  var showPriceDetailsMaybe = showPriceDetails == false ? showPriceDetails : true;

  const roomType = props.currentListing?.attributes?.description
    ? props.currentListing?.attributes?.description
    : 'shared_bedroom';
  const isExperienceType =
    props.currentListing?.attributes?.publicData?.listingType == 'impact-experience';

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={booking}
        code={lineItemUnitType}
        dateType={dateType}
        timeZone={timeZone}
        showPriceDetails={showPriceDetails}
      />
      {isExperienceType ? <div className={css.dividerSpace}></div> : <></>}
      <LineItemBasePriceMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        intl={intl}
        showPriceDetailsMaybe={showPriceDetailsMaybe}
        roomType={roomType}
        currentListing={currentListing}
      />
      {showPriceDetailsMaybe && !isExperienceType ? (
        <>
          <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
          <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
          <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

          <LineItemCustomerCommissionMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            marketplaceName={marketplaceName}
            intl={intl}
          />

          <LineItemCustomerCommissionRefundMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            marketplaceName={marketplaceName}
            intl={intl}
          />
          <LineItemProviderCommissionMaybe
            lineItems={lineItems}
            transaction={transaction}
            currentListing={currentListing}
            isProvider={isProvider}
            marketplaceName={marketplaceName}
            intl={intl}
            strikeThroughTotal={strikeThroughTotal}
            discountDataMaybe={props.discountDataMaybe}
          />
        </>
      ) : null}
      {showPriceDetailsMaybe && !isExperienceType ? (
        <>
          {' '}
          <hr className={css.totalDivider} />{' '}
        </>
      ) : (
        <>
          {' '}
          <hr className={css.invisibleTotalDivider} />{' '}
        </>
      )}

      {!isOrderIdInList && (
        <>
          {isEarlyExperienceBooking && isCustomer && !isPayedState && !isCancelledState && (
            <div className={css.paymentBreakdownSeparator}>
              <div className={css.discountTextFinal}>
                {isTransactionPage ? (
                  <FormattedMessage id="PaymentForm.amount.pay.paid.transaction" />
                ) : (
                  <FormattedMessage id="PaymentForm.amount.pay.now" />
                )}
                <div>{'€' + amount} </div>
              </div>
              <div className={css.discountTextFinal}>
                {isPayableState ? (
                  <FormattedMessage id="PaymentForm.amount.pay.now" />
                ) : (
                  <FormattedMessage id="PaymentForm.amount.pay.later" />
                )}
                <div>{'€' + restAmount} </div>
              </div>
            </div>
          )}
        </>
      )}

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        strikeThroughTotal={strikeThroughTotal}
        currentListing={currentListing}
      />

      {transaction?.attributes?.protectedData?.discountData?.discountData != undefined &&
      !location.pathname.endsWith('/checkout') ? (
        <div className={css.discountData}>
          <div className={css.discountDataWrapper}>
            <div className={css.discountTextFinal}>
              <FormattedMessage id="TransactionPanel.discountTotal" />
            </div>
            <div className={css.discountPriceFinal}>
              €
              {Number(
                transaction?.attributes?.protectedData?.discountData?.discountTotalAmount
              ).toFixed(2)}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,
  showPriceDetails: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
